import React from 'react';
import { motion } from 'framer-motion';
import { Building2, Sparkles, Target, Zap, CheckCircle, HelpCircle, Palette, Globe } from 'lucide-react';
import NameGenerator from '../../components/NameGenerator';
import ResultsList from '../../components/ResultsList';
import { GeneratedName } from '../../types';

const AgencyNameGenerator: React.FC = () => {
  const [generatedNames, setGeneratedNames] = React.useState<GeneratedName[]>([]);

  const handleGenerateNames = (names: GeneratedName[]) => {
    setGeneratedNames(names);
  };

  const features = [
    {
      icon: <Palette className="w-6 h-6" />,
      title: "Creative Focus",
      description: "Names that reflect creativity and innovation in the agency space"
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Industry-Specific",
      description: "Tailored for creative, marketing, and consulting agencies"
    },
    {
      icon: <CheckCircle className="w-6 h-6" />,
      title: "Professional Appeal",
      description: "Names that convey expertise and authority"
    }
  ];

  const examples = [
    {
      industry: "Creative Agency",
      names: ["ArtisanLabs", "PixelPerfect", "CreativeForge"],
      description: "Bold and artistic names for creative agencies"
    },
    {
      industry: "Marketing Agency",
      names: ["GrowthSpark", "MarketMaven", "BrandBurst"],
      description: "Dynamic names for marketing agencies"
    },
    {
      industry: "Consulting Agency",
      names: ["StrategyPeak", "InsightFlow", "VisionQuest"],
      description: "Professional names for consulting firms"
    }
  ];

  const tips = [
    "Focus on your agency's specialty",
    "Consider international appeal",
    "Keep it sophisticated yet approachable",
    "Ensure easy pronunciation",
    "Think about future growth",
    "Test for industry relevance"
  ];

  const faqs = [
    {
      question: "What makes a good agency name?",
      answer: "A good agency name should reflect your expertise, be memorable, and convey professionalism while being unique in your industry."
    },
    {
      question: "Should I include my specialty in the name?",
      answer: "While it can be beneficial, consider a broader name if you plan to expand your services in the future."
    },
    {
      question: "How do I ensure my agency name is unique?",
      answer: "Our generator checks for trademark conflicts and domain availability to help ensure your chosen name is unique and available."
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
        >
          <Building2 className="w-12 h-12 text-violet-400" />
        </motion.div>
        <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
          Agency Name Generator
        </h1>
        <p className="text-xl text-gray-300 max-w-2xl mx-auto">
          Create a powerful and professional name for your agency that stands out in the market
        </p>
      </motion.div>

      {/* Generator Tool */}
      <NameGenerator onGenerateNames={handleGenerateNames} />
      <ResultsList names={generatedNames} onGenerateNames={handleGenerateNames} />

      {/* How It Works */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">How It Works</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <Target className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">1. Define Your Agency</h3>
            <p className="text-gray-300">Specify your agency's focus and target market</p>
          </motion.div>
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <Sparkles className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">2. Generate Names</h3>
            <p className="text-gray-300">Get creative, industry-specific name suggestions</p>
          </motion.div>
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <CheckCircle className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">3. Validate & Choose</h3>
            <p className="text-gray-300">Check availability and select your perfect name</p>
          </motion.div>
        </div>
      </section>

      {/* Examples */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Example Names</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {examples.map((example, index) => (
            <motion.div
              key={index}
              className="glass-effect p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-white">{example.industry}</h3>
              <ul className="space-y-2 mb-4">
                {example.names.map((name, i) => (
                  <li key={i} className="text-gray-300">{name}</li>
                ))}
              </ul>
              <p className="text-sm text-gray-400">{example.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Tips */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Naming Tips</h2>
        <div className="grid md:grid-cols-2 gap-6">
          {tips.map((tip, index) => (
            <motion.div
              key={index}
              className="glass-effect p-4 rounded-xl flex items-center gap-4"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="p-2 bg-violet-500/10 rounded-lg">
                <Zap className="w-5 h-5 text-violet-400" />
              </div>
              <p className="text-gray-300">{tip}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* FAQ */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Frequently Asked Questions</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              className="glass-effect p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="flex items-start gap-4">
                <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                  <HelpCircle className="w-5 h-5 text-violet-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">{faq.question}</h3>
                  <p className="text-gray-300">{faq.answer}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AgencyNameGenerator;