import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CookieProvider } from './contexts/CookieContext';
import { ThemeProvider } from './contexts/ThemeContext';
import Header from './components/Header';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Features from './pages/Features';
import Contact from './pages/Contact';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cookie from './pages/Cookie';
import GDPR from './pages/GDPR';
import AcceptableUse from './pages/AcceptableUse';
import FAQ from './pages/FAQ';
import Disclaimer from './pages/Disclaimer';
import Tools from './pages/Tools';
import BusinessNameGenerator from './pages/tools/BusinessNameGenerator';
import AgencyNameGenerator from './pages/tools/AgencyNameGenerator';
import SaasNameGenerator from './pages/tools/SaasNameGenerator';
import OnlineStoreNameGenerator from './pages/tools/OnlineStoreNameGenerator';
import FashionBrandNameGenerator from './pages/tools/FashionBrandNameGenerator';
import DomainNameGenerator from './pages/tools/DomainNameGenerator';
import StartupNameGenerator from './pages/tools/StartupNameGenerator';
import TestStripe from './components/TestStripe';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <AuthProvider>
          <CookieProvider>
            <Router>
              <div className="min-h-screen bg-black flex flex-col">
                <Header />
                <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/cookie" element={<Cookie />} />
                    <Route path="/gdpr" element={<GDPR />} />
                    <Route path="/acceptable-use" element={<AcceptableUse />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    <Route path="/tools" element={<Tools />} />
                    <Route path="/tools/business-name-generator" element={<BusinessNameGenerator />} />
                    <Route path="/tools/agency-name-generator" element={<AgencyNameGenerator />} />
                    <Route path="/tools/saas-name-generator" element={<SaasNameGenerator />} />
                    <Route path="/tools/online-store-name-generator" element={<OnlineStoreNameGenerator />} />
                    <Route path="/tools/fashion-brand-name-generator" element={<FashionBrandNameGenerator />} />
                    <Route path="/tools/domain-name-generator" element={<DomainNameGenerator />} />
                    <Route path="/tools/startup-name-generator" element={<StartupNameGenerator />} />
                    <Route path="/test-stripe" element={<TestStripe />} />
                  </Routes>
                </main>
                <Footer />
                <CookieConsent />
              </div>
            </Router>
          </CookieProvider>
        </AuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;