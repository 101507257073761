import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Zap, Menu, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <header className="bg-black/80 backdrop-blur-md border-b border-gray-800 transition-all duration-200">
      <nav className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="group flex items-center">
            <motion.div
              className="relative p-2 rounded-xl bg-gradient-to-r from-violet-600/10 to-violet-800/10"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Zap 
                className="w-8 h-8 text-violet-400 transform transition-transform group-hover:rotate-12 logo-icon"
              />
              <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-violet-600/20 to-violet-800/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity" />
            </motion.div>
            <span className="ml-2 text-2xl font-bold bg-gradient-to-r from-violet-600 to-violet-400 bg-clip-text text-transparent">
              BrandBolt
            </span>
          </Link>

          <div className="hidden md:flex items-center space-x-6">
            <Link to="/tools" className="text-gray-300 hover:text-violet-400 transition-colors">
              Tools
            </Link>
            <Link to="/pricing" className="text-gray-300 hover:text-violet-400 transition-colors">
              Pricing
            </Link>
            <Link to="/features" className="text-gray-300 hover:text-violet-400 transition-colors">
              Features
            </Link>
            <Link to="/contact" className="text-gray-300 hover:text-violet-400 transition-colors">
              Contact
            </Link>
            {currentUser ? (
              <motion.button 
                onClick={handleLogout} 
                className="text-gray-300 hover:text-violet-400 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Log Out
              </motion.button>
            ) : (
              <>
                <Link to="/login" className="text-gray-300 hover:text-violet-400 transition-colors">
                  Log In
                </Link>
                <Link 
                  to="/signup" 
                  className="relative group px-6 py-2 rounded-full overflow-hidden"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-violet-600 to-violet-400 opacity-90 group-hover:opacity-100 transition-opacity" />
                  <div className="absolute inset-0 bg-gradient-to-r from-violet-400 to-violet-600 opacity-0 group-hover:opacity-90 transition-opacity" />
                  <span className="relative text-white font-medium">Sign Up</span>
                </Link>
              </>
            )}
          </div>

          <motion.button
            className="md:hidden text-gray-300 hover:text-violet-400 transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </motion.button>
        </div>

        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden mt-4 space-y-4"
            >
              <Link to="/tools" className="block text-gray-300 hover:text-violet-400 transition-colors">
                Tools
              </Link>
              <Link to="/pricing" className="block text-gray-300 hover:text-violet-400 transition-colors">
                Pricing
              </Link>
              <Link to="/features" className="block text-gray-300 hover:text-violet-400 transition-colors">
                Features
              </Link>
              <Link to="/contact" className="block text-gray-300 hover:text-violet-400 transition-colors">
                Contact
              </Link>
              {currentUser ? (
                <button onClick={handleLogout} className="block text-gray-300 hover:text-violet-400 transition-colors">
                  Log Out
                </button>
              ) : (
                <>
                  <Link to="/login" className="block text-gray-300 hover:text-violet-400 transition-colors">
                    Log In
                  </Link>
                  <Link to="/signup" className="block text-center px-4 py-2 rounded-full bg-gradient-to-r from-violet-600 to-violet-400 text-white hover:opacity-90 transition-opacity">
                    Sign Up
                  </Link>
                </>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </header>
  );
};

export default Header;