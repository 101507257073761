import { supabase } from '../lib/supabase';
import { SavedName } from '../lib/supabase';
import { GeneratedName } from '../types';

export const saveName = async (userId: string, name: GeneratedName): Promise<void> => {
  const { error } = await supabase
    .from('saved_names')
    .insert([{
      user_id: userId,
      name: name.name,
      relevance: name.relevance,
      score: name.score,
      domains: name.domain ? [{ domain: name.domain, available: name.available }] : []
    }]);

  if (error) throw error;
};

export const getSavedNames = async (userId: string): Promise<SavedName[]> => {
  const { data, error } = await supabase
    .from('saved_names')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
};

export const deleteSavedName = async (nameId: string): Promise<void> => {
  const { error } = await supabase
    .from('saved_names')
    .delete()
    .eq('id', nameId);

  if (error) throw error;
};