import React from 'react';
import { Zap, Globe, Shield, Palette, Cpu, Users } from 'lucide-react';
import { motion } from 'framer-motion';

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="glass-effect p-6 rounded-xl border border-gray-800 hover:border-violet-500 transition-all duration-200"
  >
    <div className="p-2 bg-violet-500/10 rounded-lg w-fit mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </motion.div>
);

const Features: React.FC = () => {
  return (
    <div className="max-w-6xl mx-auto px-4">
      <motion.h1 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl md:text-5xl font-extrabold text-center mb-4 text-gradient"
      >
        Powerful Features
      </motion.h1>
      <motion.p 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="text-xl text-center mb-12 text-gray-300"
      >
        Discover how our AI naming tool can help you create the perfect brand
      </motion.p>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
        <FeatureCard
          icon={<Zap size={24} className="text-violet-400" />}
          title="AI-Powered Generation"
          description="Our advanced algorithms generate creative and relevant business names tailored to your industry."
        />
        <FeatureCard
          icon={<Globe size={24} className="text-violet-400" />}
          title="Instant Domain Check"
          description="Check domain availability in real-time across popular TLDs for your chosen name."
        />
        <FeatureCard
          icon={<Shield size={24} className="text-violet-400" />}
          title="Trademark Screening"
          description="Preliminary trademark checks to help avoid potential legal conflicts."
        />
        <FeatureCard
          icon={<Palette size={24} className="text-violet-400" />}
          title="Brand Identity"
          description="Get instant logo design ideas based on your generated name."
        />
        <FeatureCard
          icon={<Cpu size={24} className="text-violet-400" />}
          title="Smart Analysis"
          description="Gain insights into name performance and market trends."
        />
        <FeatureCard
          icon={<Users size={24} className="text-violet-400" />}
          title="Team Collaboration"
          description="Work seamlessly with your team to find the perfect name."
        />
      </div>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="mt-16 text-center"
      >
        <h2 className="text-2xl font-bold mb-4 text-white">Ready to Get Started?</h2>
        <p className="text-gray-300 mb-6">Generate your perfect business name today.</p>
        <motion.a
          href="/"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="inline-block py-3 px-8 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl text-white font-semibold hover:opacity-90 transition-opacity"
        >
          Try Our Generator
        </motion.a>
      </motion.div>
    </div>
  );
};

export default Features;