import { loadStripe } from '@stripe/stripe-js';
import env from '../../config/env';

export const stripePromise = loadStripe(env.STRIPE_PUBLISHABLE_KEY);

export const STRIPE_PRICES = {
  FREE: 'price_1QUk0LG6rgNaLDQXBIhJlpib',
  PRO: 'price_1QUhtIG6rgNaLDQXCubngO38',
  ENTERPRISE: 'price_enterprise'  // Replace with your actual Stripe Price ID when ready
} as const;

export type StripePriceId = typeof STRIPE_PRICES[keyof typeof STRIPE_PRICES];

export interface CreateCheckoutSessionParams {
  priceId: StripePriceId;
  userId: string;
  email?: string;
  successUrl?: string;
  cancelUrl?: string;
}

export interface CreatePortalSessionParams {
  customerId: string;
  returnUrl?: string;
}