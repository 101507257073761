import axios from 'axios';

interface WhoisInfo {
  createdDate?: string;
  expirationDate?: string;
  registrar?: string;
  nameServers?: string[];
}

interface DomainCheckResult {
  isAvailable: boolean;
  domain: string;
  error?: string;
  whoisInfo?: WhoisInfo;
}

export const checkDomainsAvailability = async (
  domains: string[]
): Promise<Record<string, DomainCheckResult>> => {
  try {
    // Simulate domain check results since we can't do actual WHOIS lookups in the browser
    const results: Record<string, DomainCheckResult> = {};
    
    domains.forEach(domain => {
      // Simple simulation based on domain length and random factor
      const isAvailable = Math.random() > 0.5;
      
      results[domain] = {
        isAvailable,
        domain,
        whoisInfo: isAvailable ? undefined : {
          createdDate: '2020-01-01',
          expirationDate: '2025-01-01',
          registrar: 'Example Registrar',
          nameServers: ['ns1.example.com', 'ns2.example.com']
        }
      };
    });

    return results;
  } catch (error) {
    console.error('Error checking domains:', error);
    return domains.reduce((acc, domain) => {
      acc[domain] = {
        isAvailable: false,
        domain,
        error: 'Failed to check domain availability'
      };
      return acc;
    }, {} as Record<string, DomainCheckResult>);
  }
};