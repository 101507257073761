import React, { useState } from 'react';
import NameGenerator from '../components/NameGenerator';
import ResultsList from '../components/ResultsList';
import { GeneratedName } from '../types';
import { Sparkles, Zap, Globe, Shield, Rocket } from 'lucide-react';
import { motion } from 'framer-motion';

const Home: React.FC = () => {
  const [generatedNames, setGeneratedNames] = useState<GeneratedName[]>([]);

  const handleGenerateNames = (names: GeneratedName[]) => {
    setGeneratedNames(names);
  };

  const features = [
    {
      icon: <Globe className="w-5 h-5" />,
      text: "Domain Availability Check"
    },
    {
      icon: <Sparkles className="w-5 h-5" />,
      text: "AI-Powered Suggestions"
    },
    {
      icon: <Rocket className="w-5 h-5" />,
      text: "Instant Results"
    }
  ];

  return (
    <div className="space-y-12">
      {/* Hero Section */}
      <section className="text-center py-16 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-violet-600/10 to-transparent" />
        <div className="max-w-4xl mx-auto px-4 relative z-10">
          <motion.h1 
            className="text-4xl md:text-6xl font-extrabold mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <span className="text-gradient">
              Generate Your Perfect Brand Name
            </span>
          </motion.h1>
          <motion.p 
            className="text-xl text-gray-300 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
          >
            Harness the power of AI to create unique, memorable brand names and instantly check domain availability.
          </motion.p>

          {/* Feature Indicators */}
          <motion.div 
            className="flex flex-wrap justify-center gap-8 mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="flex items-center gap-2 text-gray-300"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 + index * 0.1 }}
              >
                <div className="p-2 bg-violet-500/10 rounded-lg">
                  {feature.icon}
                </div>
                <span>{feature.text}</span>
              </motion.div>
            ))}
          </motion.div>

          <motion.div 
            className="flex justify-center space-x-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <motion.a
              href="#generator"
              className="px-8 py-3 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl text-white font-semibold hover:opacity-90 transition-opacity"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Get Started
            </motion.a>
            <motion.a
              href="/features"
              className="px-8 py-3 bg-white/5 border border-violet-500/20 rounded-xl text-white font-semibold hover:bg-white/10 transition-all"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Learn More
            </motion.a>
          </motion.div>
        </div>
      </section>

      {/* Name Generator */}
      <section id="generator" className="max-w-4xl mx-auto px-4">
        <NameGenerator onGenerateNames={handleGenerateNames} />
        <ResultsList names={generatedNames} />
      </section>

      {/* Features Grid */}
      <section className="py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-gradient">Why Choose BrandBolt?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div 
              className="glass-effect p-6 rounded-xl border border-gray-800 hover:border-violet-500 transition-all duration-200"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-2 bg-violet-500/10 rounded-lg w-fit mb-4">
                <Sparkles className="text-violet-400 w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">AI-Powered</h3>
              <p className="text-gray-300">Generate creative and relevant business names tailored to your industry.</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl border border-gray-800 hover:border-violet-500 transition-all duration-200"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-2 bg-violet-500/10 rounded-lg w-fit mb-4">
                <Globe className="text-violet-400 w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">Instant Check</h3>
              <p className="text-gray-300">Check domain availability and trademark conflicts in real-time.</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl border border-gray-800 hover:border-violet-500 transition-all duration-200"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-2 bg-violet-500/10 rounded-lg w-fit mb-4">
                <Shield className="text-violet-400 w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">Smart Analysis</h3>
              <p className="text-gray-300">Get insights on name performance and market trends.</p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-16">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-gradient">What Our Users Say</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <motion.div 
              className="glass-effect p-6 rounded-xl"
              whileHover={{ scale: 1.02 }}
            >
              <p className="text-gray-300 mb-4">"BrandBolt helped us find the perfect name for our startup in minutes. Highly recommended!"</p>
              <p className="font-semibold text-violet-400">- Sarah J., Tech Entrepreneur</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl"
              whileHover={{ scale: 1.02 }}
            >
              <p className="text-gray-300 mb-4">"The AI-generated names were creative and relevant. It saved us weeks of brainstorming."</p>
              <p className="font-semibold text-violet-400">- Mike T., Marketing Director</p>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;