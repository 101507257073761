import axios from 'axios';
import { getApiConfig } from '../config/constants';

const config = getApiConfig();

const api = axios.create({
  baseURL: config.baseURL,
  timeout: config.timeout,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add request interceptor for CORS and authentication
api.interceptors.request.use(
  (config) => {
    // Add any auth headers here if needed
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      throw new Error('Network error. Please check your connection and try again.');
    }

    switch (error.response.status) {
      case 429:
        throw new Error('Rate limit exceeded. Please try again later.');
      case 401:
        throw new Error('Authentication required. Please log in.');
      case 403:
        throw new Error('Access denied. Please check your subscription.');
      default:
        throw new Error(error.response.data?.error || 'An unexpected error occurred.');
    }
  }
);

export default api;