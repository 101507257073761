import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles } from 'lucide-react';
import { GeneratedName } from '../types';
import { checkDomainsAvailability } from '../services/domainService';
import PremiumModal from './PremiumModal';
import NameCard from './results/NameCard';
import TldSelector from './results/TldSelector';

interface ResultsListProps {
  names: GeneratedName[];
  onGenerateNames: (names: GeneratedName[]) => void;
}

const ResultsList: React.FC<ResultsListProps> = ({ names, onGenerateNames }) => {
  const [domainStatuses, setDomainStatuses] = useState<Record<string, any>>({});
  const [isGeneratingMore, setIsGeneratingMore] = useState(false);
  const [selectedTlds, setSelectedTlds] = useState<string[]>(['com']);
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(false);
  const [isAutoGenerating, setIsAutoGenerating] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const checkDomains = async (namesToCheck: string[]) => {
    const domains = namesToCheck.flatMap(name => 
      selectedTlds.map(tld => `${formatDomainName(name)}.${tld}`)
    );
    
    setDomainStatuses(prev => {
      const updates: Record<string, any> = {};
      domains.forEach(domain => {
        updates[domain] = { 
          ...prev[domain], 
          isChecking: true,
          error: undefined,
          domain
        };
      });
      return { ...prev, ...updates };
    });

    try {
      const results = await checkDomainsAvailability(domains);
      setDomainStatuses(prev => ({
        ...prev,
        ...results
      }));

      if (showOnlyAvailable) {
        const availableCount = getAvailableNamesCount();
        if (availableCount < 5 && !isAutoGenerating) {
          await handleAutoGenerate();
        }
      }
    } catch (error) {
      console.error('Error checking domains:', error);
      setDomainStatuses(prev => {
        const updates: Record<string, any> = {};
        domains.forEach(domain => {
          updates[domain] = {
            isAvailable: false,
            isChecking: false,
            domain,
            error: 'Failed to check domain availability'
          };
        });
        return { ...prev, ...updates };
      });
    }
  };

  const formatDomainName = (name: string) => {
    return name.toLowerCase().replace(/[^a-z0-9-]/g, '');
  };

  const getAvailableNamesCount = () => {
    return names.filter(name => {
      const baseDomain = formatDomainName(name.name);
      return selectedTlds.some(tld => {
        const domain = `${baseDomain}.${tld}`;
        const status = domainStatuses[domain];
        return status && status.isAvailable;
      });
    }).length;
  };

  const handleAutoGenerate = async () => {
    if (isAutoGenerating) return;

    setIsAutoGenerating(true);
    try {
      const newNames = await generateNewNames();
      onGenerateNames([...names, ...newNames]);
    } catch (error) {
      console.error('Error generating names:', error);
    } finally {
      setIsAutoGenerating(false);
    }
  };

  const generateNewNames = async (): Promise<GeneratedName[]> => {
    return new Promise(resolve => {
      setTimeout(() => {
        const newNames = Array(3).fill(null).map((_, i) => ({
          name: `New Name ${Date.now()}${i}`,
          relevance: 'Auto-generated name',
          score: Math.floor(Math.random() * 100),
          domain: '',
          available: true
        }));
        resolve(newNames);
      }, 1500);
    });
  };

  const handleGenerateMore = async () => {
    setIsGeneratingMore(true);
    try {
      const newNames = await generateNewNames();
      onGenerateNames([...names, ...newNames]);
    } finally {
      setIsGeneratingMore(false);
    }
  };

  useEffect(() => {
    if (names.length > 0) {
      const uncheckedNames = names.map(name => name.name);
      if (uncheckedNames.length > 0) {
        checkDomains(uncheckedNames);
      }
    }
  }, [names, selectedTlds]);

  useEffect(() => {
    if (showOnlyAvailable) {
      const availableCount = getAvailableNamesCount();
      if (availableCount < 5 && !isAutoGenerating) {
        handleAutoGenerate();
      }
    }
  }, [showOnlyAvailable, domainStatuses]);

  if (names.length === 0) {
    return null;
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="glass-effect p-6 sm:p-8 rounded-2xl mt-8"
      >
        <TldSelector
          selectedTlds={selectedTlds}
          setSelectedTlds={setSelectedTlds}
          showOnlyAvailable={showOnlyAvailable}
          setShowOnlyAvailable={setShowOnlyAvailable}
          onShowPremiumModal={() => setShowPremiumModal(true)}
        />

        <div className="space-y-4 sm:space-y-6">
          <AnimatePresence mode="wait">
            {names.map((name, index) => (
              <NameCard
                key={index}
                name={name}
                index={index}
                onRetry={(name) => checkDomains([name])}
                selectedTlds={selectedTlds}
                domainStatuses={domainStatuses}
                showOnlyAvailable={showOnlyAvailable}
                onShowPremiumModal={() => setShowPremiumModal(true)}
              />
            ))}
          </AnimatePresence>
        </div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-8 text-center"
        >
          <motion.button
            onClick={handleGenerateMore}
            disabled={isGeneratingMore || isAutoGenerating}
            className="px-8 py-3 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl text-white font-semibold flex items-center justify-center gap-2 mx-auto hover:opacity-90 transition-opacity"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isGeneratingMore || isAutoGenerating ? (
              <>
                <Sparkles className="animate-spin" size={20} />
                {isAutoGenerating ? 'Auto-generating...' : 'Generating...'}
              </>
            ) : (
              <>
                <Sparkles size={20} />
                Generate More Names
              </>
            )}
          </motion.button>
        </motion.div>
      </motion.div>

      <PremiumModal 
        isOpen={showPremiumModal} 
        onClose={() => setShowPremiumModal(false)} 
      />
    </>
  );
};

export default ResultsList;