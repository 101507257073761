import { createClient } from '@supabase/supabase-js';
import env from '../config/env';

// Validate and format Supabase URL
const validateSupabaseUrl = (url: string | undefined): string => {
  if (!url) {
    if (import.meta.env.DEV) {
      return 'https://example.supabase.co';
    }
    throw new Error('VITE_SUPABASE_URL is required in production');
  }

  try {
    // Ensure URL is properly formatted
    const parsedUrl = new URL(url);
    return parsedUrl.toString();
  } catch (error) {
    if (import.meta.env.DEV) {
      console.warn('Invalid SUPABASE_URL, using fallback');
      return 'https://example.supabase.co';
    }
    throw new Error('Invalid VITE_SUPABASE_URL');
  }
};

// Validate Supabase anon key
const validateAnonKey = (key: string | undefined): string => {
  if (!key) {
    if (import.meta.env.DEV) {
      return 'example-anon-key';
    }
    throw new Error('VITE_SUPABASE_ANON_KEY is required in production');
  }
  return key;
};

// Create Supabase client with validated config
export const supabase = createClient(
  validateSupabaseUrl(env.SUPABASE_URL),
  validateAnonKey(env.SUPABASE_ANON_KEY),
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true
    }
  }
);

// Type definitions
export interface Profile {
  id: string;
  email: string;
  full_name?: string;
  subscription_tier?: string;
  created_at?: string;
  updated_at?: string;
}

export interface SavedName {
  id: string;
  user_id: string;
  name: string;
  relevance: string;
  score: number;
  domains: any[];
  created_at: string;
}