import React, { createContext, useContext, useState, useEffect } from 'react';

interface CookiePreferences {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
}

interface CookieContextType {
  cookiePreferences: CookiePreferences;
  hasConsented: boolean;
  updatePreferences: (preferences: Partial<CookiePreferences>) => void;
  acceptAll: () => void;
  rejectAll: () => void;
}

const defaultPreferences: CookiePreferences = {
  necessary: true, // Always true as these are essential
  analytics: false,
  marketing: false,
};

const CookieContext = createContext<CookieContextType>({
  cookiePreferences: defaultPreferences,
  hasConsented: false,
  updatePreferences: () => {},
  acceptAll: () => {},
  rejectAll: () => {},
});

export const useCookies = () => useContext(CookieContext);

export const CookieProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cookiePreferences, setCookiePreferences] = useState<CookiePreferences>(defaultPreferences);
  const [hasConsented, setHasConsented] = useState(false);

  useEffect(() => {
    const savedPreferences = localStorage.getItem('cookiePreferences');
    const savedConsent = localStorage.getItem('cookieConsent');
    
    if (savedPreferences) {
      setCookiePreferences(JSON.parse(savedPreferences));
    }
    if (savedConsent) {
      setHasConsented(true);
    }
  }, []);

  const savePreferences = (preferences: CookiePreferences) => {
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    localStorage.setItem('cookieConsent', 'true');
    setCookiePreferences(preferences);
    setHasConsented(true);
  };

  const updatePreferences = (preferences: Partial<CookiePreferences>) => {
    const newPreferences = {
      ...cookiePreferences,
      ...preferences,
      necessary: true, // Always keep necessary cookies enabled
    };
    savePreferences(newPreferences);
  };

  const acceptAll = () => {
    const allEnabled = {
      necessary: true,
      analytics: true,
      marketing: true,
    };
    savePreferences(allEnabled);
  };

  const rejectAll = () => {
    const allDisabled = {
      necessary: true, // Keep necessary cookies
      analytics: false,
      marketing: false,
    };
    savePreferences(allDisabled);
  };

  return (
    <CookieContext.Provider
      value={{
        cookiePreferences,
        hasConsented,
        updatePreferences,
        acceptAll,
        rejectAll,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};