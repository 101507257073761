import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Eye, Server } from 'lucide-react';

const Privacy: React.FC = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto px-4 py-12"
    >
      <div className="text-center mb-12">
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
        >
          <Shield className="w-12 h-12 text-violet-400" />
        </motion.div>
        <h1 className="text-4xl font-bold mb-4 text-gradient">Privacy Policy</h1>
        <p className="text-gray-400">Last Updated: {currentDate}</p>
      </div>

      <div className="space-y-12">
        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">1. Introduction</h2>
          <div className="glass-effect p-6 rounded-xl">
            <p className="text-gray-300">
              Welcome to BrandBolt.ai. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our brand name and domain name generator service.
            </p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">2. Information We Collect</h2>
          <div className="space-y-6">
            <div className="glass-effect p-6 rounded-xl">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                  <Eye className="w-5 h-5 text-violet-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">2.1 Information You Provide</h3>
                  <ul className="list-disc list-inside text-gray-300 space-y-2">
                    <li>Search queries for brand names and domains</li>
                    <li>Email address (if you choose to save or receive results)</li>
                    <li>Account information (if you create an account)</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="glass-effect p-6 rounded-xl">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                  <Server className="w-5 h-5 text-violet-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">2.2 Automatically Collected Information</h3>
                  <ul className="list-disc list-inside text-gray-300 space-y-2">
                    <li>Log data (IP address, browser type, pages visited)</li>
                    <li>Device information</li>
                    <li>Cookies and similar tracking technologies</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">3. How We Use Your Information</h2>
          <div className="glass-effect p-6 rounded-xl">
            <ul className="text-gray-300 space-y-2">
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                Generate brand name and domain suggestions
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                Improve our name generation algorithms
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                Provide customer support
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                Send service-related communications
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                Maintain and improve our service
              </li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">4. Data Security</h2>
          <div className="glass-effect p-6 rounded-xl">
            <div className="flex items-start gap-4">
              <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                <Lock className="w-5 h-5 text-violet-400" />
              </div>
              <p className="text-gray-300">
                We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, or destruction. Our security practices include encryption, access controls, and regular security assessments.
              </p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white">5. Third-Party Services</h2>
          <div className="glass-effect p-6 rounded-xl">
            <p className="text-gray-300 mb-4">We may use third-party services for:</p>
            <ul className="text-gray-300 space-y-2">
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                Domain availability checking
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                Analytics
              </li>
              <li className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                Payment processing (if applicable)
              </li>
            </ul>
          </div>
        </section>
      </div>
    </motion.div>
  );
};

export default Privacy;