import { useAuth } from '../contexts/AuthContext';

interface UsageData {
  count: number;
  date: string;
}

const DAILY_LIMIT = 10;
const STORAGE_KEY = 'name_generator_usage';

export const getUsageData = (): UsageData => {
  const stored = localStorage.getItem(STORAGE_KEY);
  if (!stored) {
    return { count: 0, date: new Date().toDateString() };
  }
  return JSON.parse(stored);
};

// Remove hook usage from service
export const updateUsage = (generationCount: number, isPremiumUser: boolean): boolean => {
  if (isPremiumUser) return true; // Premium users have unlimited generations

  const today = new Date().toDateString();
  const usage = getUsageData();

  // Reset count if it's a new day
  if (usage.date !== today) {
    usage.count = 0;
    usage.date = today;
  }

  // Check if adding new generations would exceed limit
  if (usage.count + generationCount > DAILY_LIMIT) {
    return false;
  }

  // Update usage count
  usage.count += generationCount;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(usage));
  return true;
};

export const getRemainingGenerations = (isPremiumUser: boolean): number => {
  if (isPremiumUser) return Infinity;

  const usage = getUsageData();
  const today = new Date().toDateString();

  if (usage.date !== today) {
    return DAILY_LIMIT;
  }

  return Math.max(0, DAILY_LIMIT - usage.count);
};