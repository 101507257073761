import React from 'react';
import { motion } from 'framer-motion';
import { ShieldAlert, XCircle, AlertTriangle, Gauge, Lock } from 'lucide-react';

const AcceptableUse: React.FC = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto px-4 py-12"
    >
      <div className="text-center mb-12">
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
        >
          <ShieldAlert className="w-12 h-12 text-violet-400" />
        </motion.div>
        <h1 className="text-4xl font-bold mb-4 text-gradient">Acceptable Use Policy</h1>
        <p className="text-gray-400">Last Updated: {currentDate}</p>
      </div>

      <div className="space-y-12">
        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
            <XCircle className="w-6 h-6 text-violet-400" />
            1. Prohibited Uses
          </h2>
          <div className="glass-effect p-6 rounded-xl space-y-6">
            <p className="text-gray-300">You may not use our service to:</p>
            
            <div className="space-y-4">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-red-500/10 rounded-lg mt-1">
                  <AlertTriangle className="w-5 h-5 text-red-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">Generate Names for Illegal Purposes</h3>
                  <p className="text-gray-300">
                    Including but not limited to fraud, scams, or any other unlawful activities.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="p-2 bg-red-500/10 rounded-lg mt-1">
                  <Lock className="w-5 h-5 text-red-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">Infringe on Intellectual Property</h3>
                  <p className="text-gray-300">
                    Deliberately generating names that violate existing trademarks or intellectual property rights.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="p-2 bg-red-500/10 rounded-lg mt-1">
                  <XCircle className="w-5 h-5 text-red-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">Generate Harmful Content</h3>
                  <p className="text-gray-300">
                    Creating offensive, discriminatory, or harmful content that violates our community standards.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="p-2 bg-red-500/10 rounded-lg mt-1">
                  <ShieldAlert className="w-5 h-5 text-red-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">System Manipulation</h3>
                  <p className="text-gray-300">
                    Any attempts to manipulate, hack, or bypass our system's security measures or limitations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
            <Gauge className="w-6 h-6 text-violet-400" />
            2. Service Limitations
          </h2>
          <div className="glass-effect p-6 rounded-xl">
            <div className="space-y-6">
              <div className="border-b border-gray-700 pb-6">
                <h3 className="text-lg font-semibold mb-4 text-white">Free Plan Limitations</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">Maximum of 15 name generations per day</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">Basic domain availability check (.com only)</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">Save up to 10 name ideas</span>
                  </li>
                </ul>
              </div>

              <div className="border-b border-gray-700 pb-6">
                <h3 className="text-lg font-semibold mb-4 text-white">Pro Plan Limitations</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">Maximum of 150 name generations per day</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">Extended domain availability check (8 TLDs)</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">Unlimited name saves</span>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4 text-white">Enterprise Plan Limitations</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">Unlimited name generations</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">All TLD domain checks</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    <span className="text-gray-300">Advanced API access with custom rate limits</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="glass-effect p-6 rounded-xl bg-violet-500/5">
            <p className="text-gray-300">
              Violation of this Acceptable Use Policy may result in temporary or permanent suspension of your account. For more information about our terms and policies, please review our{' '}
              <a href="/terms" className="text-violet-400 hover:text-violet-300 transition-colors">
                Terms of Service
              </a>
              . If you have any questions, please contact us through our{' '}
              <a href="/contact" className="text-violet-400 hover:text-violet-300 transition-colors">
                Contact Page
              </a>
              .
            </p>
          </div>
        </section>
      </div>
    </motion.div>
  );
};

export default AcceptableUse;