import React from 'react';
import { motion } from 'framer-motion';
import { Check, Eye } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface TldSelectorProps {
  selectedTlds: string[];
  setSelectedTlds: (tlds: string[]) => void;
  showOnlyAvailable: boolean;
  setShowOnlyAvailable: (show: boolean) => void;
  onShowPremiumModal: () => void;
}

const PREMIUM_TLDS = [
  { value: 'com', label: '.com' },
  { value: 'io', label: '.io' },
  { value: 'ai', label: '.ai' },
  { value: 'app', label: '.app' },
  { value: 'dev', label: '.dev' },
  { value: 'co', label: '.co' },
  { value: 'net', label: '.net' },
  { value: 'org', label: '.org' },
];

const TldSelector: React.FC<TldSelectorProps> = ({
  selectedTlds,
  setSelectedTlds,
  showOnlyAvailable,
  setShowOnlyAvailable,
  onShowPremiumModal,
}) => {
  const { currentUser } = useAuth();

  const handleTldChange = (tld: string) => {
    if (!currentUser && tld !== 'com') {
      onShowPremiumModal();
      return;
    }

    setSelectedTlds(prev => {
      const newTlds = prev.includes(tld)
        ? prev.filter(t => t !== tld)
        : [...prev, tld];
      
      if (newTlds.length === 0) return ['com'];
      return newTlds;
    });
  };

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
      <h2 className="text-2xl sm:text-3xl font-bold text-white">Generated Names</h2>
      
      <div className="flex flex-wrap items-center gap-4">
        <motion.button
          onClick={() => setShowOnlyAvailable(!showOnlyAvailable)}
          className={`px-3 py-1.5 rounded-lg text-sm font-medium transition-all duration-200 flex items-center gap-1.5
            ${showOnlyAvailable
              ? 'bg-violet-500/20 text-violet-300 border border-violet-500/30'
              : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            }`}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Eye size={14} />
          Available Only
          {showOnlyAvailable && <Check size={14} />}
        </motion.button>
        
        <div className="flex flex-wrap gap-2">
          {PREMIUM_TLDS.map(({ value, label }) => (
            <motion.button
              key={value}
              onClick={() => handleTldChange(value)}
              disabled={!currentUser && value !== 'com'}
              className={`px-3 py-1.5 rounded-lg text-sm font-medium transition-all duration-200 flex items-center gap-1.5
                ${!currentUser && value !== 'com' 
                  ? 'bg-gray-800/50 text-gray-500 cursor-not-allowed' 
                  : selectedTlds.includes(value)
                    ? 'bg-violet-500/20 text-violet-300 border border-violet-500/30'
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
              whileHover={currentUser || value === 'com' ? { scale: 1.02 } : {}}
              whileTap={currentUser || value === 'com' ? { scale: 0.98 } : {}}
            >
              {label}
              {selectedTlds.includes(value) && <Check size={14} />}
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TldSelector;