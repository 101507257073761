import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Zap, Check, Star } from 'lucide-react';
import { Link } from 'react-router-dom';

interface PremiumModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PremiumModal: React.FC<PremiumModalProps> = ({ isOpen, onClose }) => {
  const benefits = [
    'Unlimited name generations',
    'Check multiple domain TLDs',
    'Advanced name analytics',
    'Priority support',
    'Save unlimited favorites',
    'Ad-free experience'
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black/60 backdrop-blur-sm"
            onClick={onClose}
          />
          
          <motion.div
            initial={{ opacity: 0, scale: 0.9, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: 20 }}
            className="relative z-10 w-full max-w-lg glass-effect p-6 rounded-2xl"
          >
            <button
              onClick={onClose}
              className="absolute top-4 right-4 p-2 text-gray-400 hover:text-white transition-colors"
            >
              <X size={20} />
            </button>

            <div className="text-center mb-8">
              <div className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4">
                <Star className="w-8 h-8 text-violet-400" />
              </div>
              <h2 className="text-2xl font-bold mb-2 text-gradient">Unlock Premium Features</h2>
              <p className="text-gray-300">
                Take your brand naming to the next level with BrandBolt Premium
              </p>
            </div>

            <div className="space-y-4 mb-8">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex items-center gap-3">
                  <div className="p-1 bg-violet-500/10 rounded-lg">
                    <Check className="w-4 h-4 text-violet-400" />
                  </div>
                  <span className="text-gray-200">{benefit}</span>
                </div>
              ))}
            </div>

            <div className="space-y-4">
              <Link 
                to="/pricing"
                className="block w-full py-3 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl text-white font-semibold text-center hover:opacity-90 transition-opacity"
              >
                View Premium Plans
              </Link>
              <button
                onClick={onClose}
                className="block w-full py-3 bg-gray-800 rounded-xl text-gray-300 font-semibold hover:bg-gray-700 transition-colors"
              >
                Continue with Free Plan
              </button>
            </div>

            <p className="text-center text-sm text-gray-400 mt-6">
              Starting at just $19/month. Cancel anytime.
            </p>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default PremiumModal;