import { CreateCheckoutSessionParams, CreatePortalSessionParams } from './config';

const API_URL = process.env.VITE_SERVER_URL || 'https://brandbolt.ai';

export async function createCheckoutSession(params: CreateCheckoutSessionParams): Promise<{ sessionId: string }> {
  const response = await fetch(`${API_URL}/.netlify/functions/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to create checkout session');
  }

  return response.json();
}

export async function createPortalSession({ customerId, returnUrl = window.location.origin + '/account' }: CreatePortalSessionParams): Promise<{ url: string }> {
  const response = await fetch(`${API_URL}/.netlify/functions/create-portal-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ customerId, returnUrl }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to create portal session');
  }

  return response.json();
}