import { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { STRIPE_PRICES } from '../services/stripe/config';
import { createCheckoutSession } from '../services/stripe/api';

export default function TestStripe() {
  const stripe = useStripe();

  useEffect(() => {
    // Log Stripe initialization
    console.log('Stripe initialized:', !!stripe);
    console.log('Price IDs:', STRIPE_PRICES);
  }, [stripe]);

  const testCheckout = async () => {
    try {
      console.log('Starting checkout test...');
      const { sessionId } = await createCheckoutSession({
        priceId: STRIPE_PRICES.PRO,
        userId: 'test-user-id',
        email: 'test@example.com'
      });
      console.log('Checkout session created:', sessionId);

      if (!stripe) {
        throw new Error('Stripe not initialized');
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId
      });

      if (error) {
        throw error;
      }
    } catch (err) {
      console.error('Checkout test failed:', err);
      alert('Test failed: ' + (err as Error).message);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Stripe Integration Test</h1>
      <button
        onClick={testCheckout}
        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
      >
        Test Checkout
      </button>
    </div>
  );
}
