import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Cookie, Settings, ChevronDown, ChevronUp } from 'lucide-react';
import { useCookies } from '../contexts/CookieContext';
import { Link } from 'react-router-dom';

const CookieConsent: React.FC = () => {
  const { hasConsented, cookiePreferences, updatePreferences, acceptAll, rejectAll } = useCookies();
  const [showDetails, setShowDetails] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  if (hasConsented || !showBanner) {
    return null;
  }

  const handleAcceptAll = () => {
    acceptAll();
    setShowBanner(false);
  };

  const handleRejectAll = () => {
    rejectAll();
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    updatePreferences(cookiePreferences);
    setShowBanner(false);
  };

  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      className="fixed bottom-0 left-0 right-0 z-50 p-4"
    >
      <div className="max-w-4xl mx-auto">
        <div className="glass-effect p-6 rounded-xl border border-gray-800">
          <div className="flex items-start justify-between gap-4">
            <div className="flex items-start gap-4">
              <div className="p-2 bg-violet-500/10 rounded-lg">
                <Cookie className="w-6 h-6 text-violet-400" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-white mb-2">Cookie Preferences</h3>
                <p className="text-gray-300 text-sm">
                  We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. 
                  Read our{' '}
                  <Link to="/cookie" className="text-violet-400 hover:text-violet-300 transition-colors">
                    Cookie Policy
                  </Link>
                  {' '}to learn more.
                </p>
              </div>
            </div>
            <button
              onClick={() => setShowBanner(false)}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X size={20} />
            </button>
          </div>

          <div className="mt-4">
            <button
              onClick={() => setShowDetails(!showDetails)}
              className="flex items-center gap-2 text-sm text-gray-300 hover:text-white transition-colors"
            >
              <Settings size={16} />
              Cookie Settings
              {showDetails ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </button>

            <AnimatePresence>
              {showDetails && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="mt-4 space-y-4"
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-white font-medium">Necessary Cookies</p>
                      <p className="text-sm text-gray-400">Required for the website to function properly</p>
                    </div>
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={cookiePreferences.necessary}
                        disabled
                        className="sr-only"
                      />
                      <div className="w-10 h-6 bg-violet-600 rounded-full"></div>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-white font-medium">Analytics Cookies</p>
                      <p className="text-sm text-gray-400">Help us improve our website</p>
                    </div>
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={cookiePreferences.analytics}
                        onChange={(e) => updatePreferences({ analytics: e.target.checked })}
                        className="sr-only peer"
                      />
                      <div className="w-10 h-6 bg-gray-700 peer-checked:bg-violet-600 rounded-full transition-colors"></div>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-white font-medium">Marketing Cookies</p>
                      <p className="text-sm text-gray-400">Used for personalized advertisements</p>
                    </div>
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={cookiePreferences.marketing}
                        onChange={(e) => updatePreferences({ marketing: e.target.checked })}
                        className="sr-only peer"
                      />
                      <div className="w-10 h-6 bg-gray-700 peer-checked:bg-violet-600 rounded-full transition-colors"></div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="mt-6 flex flex-wrap gap-4">
            <motion.button
              onClick={handleAcceptAll}
              className="px-6 py-2 bg-gradient-to-r from-violet-600 to-violet-400 rounded-lg text-white font-medium hover:opacity-90 transition-opacity"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Accept All
            </motion.button>
            <motion.button
              onClick={handleSavePreferences}
              className="px-6 py-2 bg-gray-700 rounded-lg text-white font-medium hover:bg-gray-600 transition-colors"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Save Preferences
            </motion.button>
            <motion.button
              onClick={handleRejectAll}
              className="px-6 py-2 bg-gray-800 rounded-lg text-gray-300 font-medium hover:bg-gray-700 transition-colors"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Reject All
            </motion.button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CookieConsent;