export const API_CONFIG = {
  development: {
    baseURL: 'http://localhost:8888/.netlify/functions',
    timeout: 30000
  },
  production: {
    baseURL: '/.netlify/functions',
    timeout: 60000  // Increased timeout for production
  }
};

export const getApiConfig = () => {
  const isProd = import.meta.env.PROD;
  console.log('Environment:', isProd ? 'production' : 'development');
  console.log('Using API config:', isProd ? API_CONFIG.production : API_CONFIG.development);
  return isProd ? API_CONFIG.production : API_CONFIG.development;
};