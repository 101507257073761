import { loadStripe } from '@stripe/stripe-js';
import env from '../config/env';

const stripePromise = loadStripe(env.STRIPE_PUBLISHABLE_KEY);

export const createCheckoutSession = async (priceId: string, userId: string) => {
  try {
    console.log('Creating checkout session with:', { priceId, userId });
    
    if (!priceId || !userId) {
      console.error('Missing required fields:', { priceId, userId });
      throw new Error('Missing required fields: priceId and userId are required');
    }

    const response = await fetch(`${env.SERVER_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        userId,
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/pricing`
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Checkout session error:', errorText);
      throw new Error('Network response was not ok');
    }

    const { sessionId } = await response.json();
    console.log('Created checkout session:', sessionId);
    
    const stripe = await stripePromise;

    if (!stripe) {
      throw new Error('Stripe failed to initialize');
    }

    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};

export const createPortalSession = async (customerId: string) => {
  try {
    const response = await fetch(`${env.SERVER_URL}/create-portal-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customerId,
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const { url } = await response.json();
    window.location.href = url;
  } catch (error) {
    console.error('Error creating portal session:', error);
    throw error;
  }
};