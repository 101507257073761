import React from 'react';
import { motion } from 'framer-motion';
import { Cloud, Sparkles, Target, Zap, CheckCircle, HelpCircle, Globe, Lock } from 'lucide-react';
import NameGenerator from '../../components/NameGenerator';
import ResultsList from '../../components/ResultsList';
import { GeneratedName } from '../../types';

const SaasNameGenerator: React.FC = () => {
  const [generatedNames, setGeneratedNames] = React.useState<GeneratedName[]>([]);

  const handleGenerateNames = (names: GeneratedName[]) => {
    setGeneratedNames(names);
  };

  const features = [
    {
      icon: <Cloud className="w-6 h-6" />,
      title: "SaaS-Focused",
      description: "Names that reflect modern software and cloud technology"
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Global Appeal",
      description: "International-friendly names for worldwide reach"
    },
    {
      icon: <Lock className="w-6 h-6" />,
      title: "Domain Ready",
      description: "Instant domain availability check for .com, .io, and .ai"
    }
  ];

  const examples = [
    {
      industry: "Project Management",
      names: ["TaskFlow", "ProjectPulse", "TeamSync"],
      description: "Efficient and dynamic names for productivity tools"
    },
    {
      industry: "Analytics",
      names: ["DataSense", "MetricMind", "InsightFlow"],
      description: "Smart names for data-driven solutions"
    },
    {
      industry: "Communication",
      names: ["ChatSpace", "TeamPulse", "ConnectFlow"],
      description: "Engaging names for collaboration tools"
    }
  ];

  const tips = [
    "Keep it short and memorable",
    "Consider .io and .ai domains",
    "Make it easy to pronounce",
    "Avoid technical jargon",
    "Think about scalability",
    "Check social media handles"
  ];

  const faqs = [
    {
      question: "What makes a good SaaS name?",
      answer: "A good SaaS name should be memorable, easy to pronounce, convey your product's value, and have available domain names. It should also work well internationally and scale with your business."
    },
    {
      question: "Should I use a descriptive name or a made-up word?",
      answer: "Both approaches can work. Descriptive names help users understand your product immediately, while made-up words can be more unique and memorable. Consider your target market and marketing strategy."
    },
    {
      question: "How important is domain availability?",
      answer: "Very important for SaaS businesses. We check .com, .io, and .ai domains automatically, as these are popular in the tech industry. Consider securing multiple TLDs to protect your brand."
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
        >
          <Cloud className="w-12 h-12 text-violet-400" />
        </motion.div>
        <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
          SaaS Name Generator
        </h1>
        <p className="text-xl text-gray-300 max-w-2xl mx-auto">
          Create the perfect name for your software-as-a-service business
        </p>
      </motion.div>

      {/* Generator Tool */}
      <NameGenerator onGenerateNames={handleGenerateNames} />
      <ResultsList names={generatedNames} onGenerateNames={handleGenerateNames} />

      {/* How It Works */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">How It Works</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <Target className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">1. Describe Your SaaS</h3>
            <p className="text-gray-300">Tell us about your software and target market</p>
          </motion.div>
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <Sparkles className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">2. Generate Names</h3>
            <p className="text-gray-300">Get modern, tech-focused name suggestions</p>
          </motion.div>
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <CheckCircle className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">3. Verify & Choose</h3>
            <p className="text-gray-300">Check domain availability and make your choice</p>
          </motion.div>
        </div>
      </section>

      {/* Examples */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Example Names</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {examples.map((example, index) => (
            <motion.div
              key={index}
              className="glass-effect p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-white">{example.industry}</h3>
              <ul className="space-y-2 mb-4">
                {example.names.map((name, i) => (
                  <li key={i} className="text-gray-300">{name}</li>
                ))}
              </ul>
              <p className="text-sm text-gray-400">{example.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Tips */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Naming Tips</h2>
        <div className="grid md:grid-cols-2 gap-6">
          {tips.map((tip, index) => (
            <motion.div
              key={index}
              className="glass-effect p-4 rounded-xl flex items-center gap-4"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="p-2 bg-violet-500/10 rounded-lg">
                <Zap className="w-5 h-5 text-violet-400" />
              </div>
              <p className="text-gray-300">{tip}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* FAQ */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Frequently Asked Questions</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              className="glass-effect p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="flex items-start gap-4">
                <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                  <HelpCircle className="w-5 h-5 text-violet-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">{faq.question}</h3>
                  <p className="text-gray-300">{faq.answer}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default SaasNameGenerator;