import React from 'react';
import { motion } from 'framer-motion';
import { Globe, Sparkles, Target, Zap, CheckCircle, HelpCircle, Search, Link } from 'lucide-react';
import NameGenerator from '../../components/NameGenerator';
import ResultsList from '../../components/ResultsList';
import { GeneratedName } from '../../types';

const DomainNameGenerator: React.FC = () => {
  const [generatedNames, setGeneratedNames] = React.useState<GeneratedName[]>([]);

  const handleGenerateNames = (names: GeneratedName[]) => {
    setGeneratedNames(names);
  };

  const features = [
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Multi-TLD Check",
      description: "Check availability across multiple domain extensions"
    },
    {
      icon: <Search className="w-6 h-6" />,
      title: "SEO-Friendly",
      description: "Generate domains optimized for search engines"
    },
    {
      icon: <Link className="w-6 h-6" />,
      title: "Instant Registration",
      description: "Direct links to register available domains"
    }
  ];

  const examples = [
    {
      category: "Technology",
      domains: ["techflow.io", "bytehub.com", "devspace.app"],
      description: "Modern domains for tech companies"
    },
    {
      category: "E-commerce",
      domains: ["shopwave.com", "buybox.store", "dealspot.shop"],
      description: "Catchy domains for online stores"
    },
    {
      category: "Creative",
      domains: ["artistry.co", "designlab.studio", "creative.space"],
      description: "Unique domains for creative businesses"
    }
  ];

  const tips = [
    "Keep it short and memorable",
    "Avoid hyphens and numbers",
    "Consider alternative TLDs",
    "Check trademark conflicts",
    "Think about brandability",
    "Ensure easy pronunciation"
  ];

  const faqs = [
    {
      question: "What makes a good domain name?",
      answer: "A good domain name should be short, memorable, easy to type, and relevant to your business. It should also be free from trademark issues and available across major social media platforms."
    },
    {
      question: "Which domain extension should I choose?",
      answer: "While .com remains the most popular choice, consider alternatives like .io for tech companies, .store for e-commerce, or .app for applications. Choose an extension that matches your industry and target audience."
    },
    {
      question: "How do I secure my domain name?",
      answer: "Once you find an available domain, register it through a reputable domain registrar. Consider registering multiple TLDs and similar variations to protect your brand identity."
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
        >
          <Globe className="w-12 h-12 text-violet-400" />
        </motion.div>
        <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
          Domain Name Generator
        </h1>
        <p className="text-xl text-gray-300 max-w-2xl mx-auto">
          Find the perfect domain name for your website with instant availability checking
        </p>
      </motion.div>

      {/* Generator Tool */}
      <NameGenerator onGenerateNames={handleGenerateNames} />
      <ResultsList names={generatedNames} onGenerateNames={handleGenerateNames} />

      {/* How It Works */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">How It Works</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <Target className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">1. Enter Keywords</h3>
            <p className="text-gray-300">Describe your website or business idea</p>
          </motion.div>
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <Sparkles className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">2. Generate Domains</h3>
            <p className="text-gray-300">Get instant domain suggestions</p>
          </motion.div>
          <motion.div 
            className="glass-effect p-6 rounded-xl text-center"
            whileHover={{ scale: 1.02 }}
          >
            <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
              <CheckCircle className="w-8 h-8 text-violet-400" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">3. Check & Register</h3>
            <p className="text-gray-300">Verify availability and register your domain</p>
          </motion.div>
        </div>
      </section>

      {/* Examples */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Example Domains</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {examples.map((example, index) => (
            <motion.div
              key={index}
              className="glass-effect p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-white">{example.category}</h3>
              <ul className="space-y-2 mb-4">
                {example.domains.map((domain, i) => (
                  <li key={i} className="text-gray-300">{domain}</li>
                ))}
              </ul>
              <p className="text-sm text-gray-400">{example.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Tips */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Domain Tips</h2>
        <div className="grid md:grid-cols-2 gap-6">
          {tips.map((tip, index) => (
            <motion.div
              key={index}
              className="glass-effect p-4 rounded-xl flex items-center gap-4"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="p-2 bg-violet-500/10 rounded-lg">
                <Zap className="w-5 h-5 text-violet-400" />
              </div>
              <p className="text-gray-300">{tip}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* FAQ */}
      <section className="mt-20">
        <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Frequently Asked Questions</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              className="glass-effect p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="flex items-start gap-4">
                <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                  <HelpCircle className="w-5 h-5 text-violet-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">{faq.question}</h3>
                  <p className="text-gray-300">{faq.answer}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default DomainNameGenerator;