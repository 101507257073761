import React from 'react';
import { motion } from 'framer-motion';
import { Heart, RefreshCw } from 'lucide-react';
import { GeneratedName } from '../../types';
import DomainList from './DomainList';
import { useAuth } from '../../contexts/AuthContext';
import { saveName } from '../../services/savedNames';

interface NameCardProps {
  name: GeneratedName;
  index: number;
  onRetry: (name: string) => void;
  selectedTlds: string[];
  domainStatuses: Record<string, any>;
  showOnlyAvailable: boolean;
  onShowPremiumModal: () => void;
}

const NameCard: React.FC<NameCardProps> = ({
  name,
  index,
  onRetry,
  selectedTlds,
  domainStatuses,
  showOnlyAvailable,
  onShowPremiumModal,
}) => {
  const { currentUser } = useAuth();
  const baseDomain = name.name.toLowerCase().replace(/[^a-z0-9-]/g, '');

  const handleSave = async () => {
    if (!currentUser) {
      onShowPremiumModal();
      return;
    }

    try {
      await saveName(currentUser.id, name);
      alert('Name saved successfully!');
    } catch (error) {
      console.error('Error saving name:', error);
      alert('Failed to save name. Please try again.');
    }
  };

  const hasAvailableDomains = selectedTlds.some(tld => {
    const domain = `${baseDomain}.${tld}`;
    const status = domainStatuses[domain];
    return status && status.isAvailable;
  });

  if (showOnlyAvailable && !hasAvailableDomains) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="glass-effect p-4 sm:p-6 rounded-xl border border-gray-800 hover:border-violet-500/50 transition-all duration-200"
    >
      <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-white">{name.name}</h3>
      <p className="text-gray-300 mb-2">{name.relevance}</p>
      <div className="flex items-center justify-between">
        <span className="text-sm font-medium text-violet-400">Score: {name.score}</span>
        <div className="flex space-x-2">
          <button
            onClick={handleSave}
            className="p-2 text-gray-300 hover:text-violet-400 transition-colors"
            title="Save name"
          >
            <Heart size={20} />
          </button>
          <button 
            onClick={() => onRetry(name.name)}
            className="p-2 text-gray-300 hover:text-violet-400 transition-colors"
            title="Refresh domain check"
          >
            <RefreshCw size={20} />
          </button>
        </div>
      </div>

      <DomainList
        baseDomain={baseDomain}
        selectedTlds={selectedTlds}
        domainStatuses={domainStatuses}
        showOnlyAvailable={showOnlyAvailable}
      />
    </motion.div>
  );
};

export default NameCard;